//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SubItem from '../subItem'
export default {
  name: 'SubMenu',
  components: { SubItem },
  props: {
    menuList: {
      type: Array,
      default() {
        return []
      },
    },
    props: {
      type: Object,
      default() {
        return {
          children: 'children',
          label: 'label',
          index: 'id',
        }
      },
    },
    logoOpr: {
      type: Object,
      default: () => {},
    },
    activeFlag: {
      type: Number,
      default: null,
    },
    activeIndexChange: {
      type: String,
      default: '-1',
    },
  },
  data() {
    return {
      activeIndex: '-1',
    }
  },
  watch: {
    activeFlag: {
      handler(val) {
        if (val) {
          //如果需要改为首页
          this.activeIndex = '-1'
        }
      },
      deep: true,
      immediate: true,
    },
    activeIndexChange: {
      handler(val) {
        if (val) {
          this.activeIndex = val
        } else {
          this.activeIndex = null
        }
      },
    },
  },
  mounted() {
    this.activeIndex = this.$route.query.classification || '-1'
  },
  methods: {
    onMenuItemClick(item) {
      this.activeIndex = item[this.props.index]
      this.$emit('getmenu', item)
    },
    gotoLogoUrl() {
      if (this.logoOpr.logo_url) {
        window.open(`${this.logoOpr.logo_url_protocol}${this.logoOpr.logo_url}`)
      }
    },
    handleSelect(key) {
      this.activeIndex = key
    },
  },
}
