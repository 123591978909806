//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SubItem',
  props: {
    chil: {
      type: Array,
      default() {
        return []
      },
    },
    props: {
      type: Object,
      default() {
        return {
          children: 'children',
          label: 'label',
          index: 'id',
        }
      },
    },
  },
  methods: {
    onSubTtemClick(item) {
      this.$emit('getSubItem', item)
    },
  },
}
